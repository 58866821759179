import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Box, Text, Button, Image, Divider, Embed } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"

const shortcodes = { Box, Text, Button, Image, Divider, Embed }

export default function PostTemplate({ data: { mdx } }) {  
  return (
    <Layout>
      <SEO title={mdx.frontmatter.title} />
      <Text as="h2" variant="h2">{mdx.frontmatter.title}</Text>
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

export const postQuery = graphql`
  query PostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date(formatString: "YYYY")
      }
    }
  }
`